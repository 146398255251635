<template>
  <div>
    <div
      v-if="isFailed"
      class="flex items-center justify-center h-screen text-xl font-medium text-oDark"
    >
      {{ failedMessage }}
    </div>
    <div
      v-else-if="getActiveLoaderState && !isFailed"
      class="h-screen vld-parent"
    >
      <loading :active="getActiveLoaderState" :is-full-page="false" />
    </div>
    <div v-else>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheMystery',
  props: {
    // should the loader be active?
    isActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    // is loading finished? oppositional alias for isActive
    // it has a higher priority than isActive
    isLoaded: {
      type: Boolean,
      required: false,
    },
    isFailed: {
      type: Boolean,
      required: false,
      default: false,
    },
    failedMessage: {
      type: String,
      required: false,
      default: 'Error occured!',
    },
  },
  computed: {
    getActiveLoaderState() {
      if (this.isLoaded === false) {
        return true
      }

      return this.isActive === true ? true : false
    },
  },
}
</script>

<style lang="scss" scoped></style>
